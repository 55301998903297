import axios from "axios";
axios.defaults.baseURL = `${process.env.baseUrl}/api`;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.common["Authorization"] = `${process.env.auth}`;

export const fetchPromos = async (params) => {
  try {
    const response = await axios.get("/v1/kentico/promo-list", { params });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const fetchOfflineShop = async (url) => {
  try {
    const response = await axios.get("/v1/static/offlineShop");
    return response.data;
  } catch (error) {
    return {
      errorCode: 404,
      message: "Terjadi kesalahan pada sistem kami. Mohon kembali dalam beberapa menit"
    };
  }
};

export const fetchByItems = async (itemName) => {
  try {
    const response = await axios.get("/v1/kentico/by-items?name=" + itemName);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const fetchPers = async (slug) => {
  try {
    const response = await axios.get("/v1/kentico/pers" + slug);

    return response.data;
  } catch (error) {
    return error;
  }
};

export const fetchEvent = async (slug) => {
  try {
    const response = await axios.get("/v1/kentico/events" + slug);

    return response.data;
  } catch (error) {
    return error;
  }
};

export const fetchNearestStore = async (data) => {
  try {
    const response = await axios.post("/v1/maps", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const fetchDealerMotor = async (slug) => {
  try {
    const response = await axios.get("/v1/kentico/two-wheeler-location?brand=" + slug);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const fetchInfoPage = async (slug) => {
  try {
    const response = await axios.get("/v1/kentico/info-page?slug=" + slug);
    return response.data;
  } catch (error) {
    return { errorCode: error.response.status, message: error.response.data };
  }
};

//SLIK
export const postPhotoId = async (formPhoto) => {
  try {
    const response = await axios.post("/v1/slik/photo-id", formPhoto);

    return response.data;
  } catch (error) {
    return { errorCode: error.response.status, message: error.response.data };
  }
};

export const postSlikForm = async (data) => {
  try {
    const response = await axios.post("/v1/slik/input", data);

    return response.data;
  } catch (error) {
    return { errorCode: error.response.status, message: error.response.data };
  }
};

//Payment Holiday
export const postPaymentHoliday = async (data) => {
  try {
    const response = await axios.post("/v1/form/payment-holiday", data);

    return response.data;
  } catch (error) {
    return { errorCode: error.response.status, message: error.response.data };
  }
};

//Login
export const postLogin = async (data) => {
  try {
    const response = await axios.post("https://api-preprod.homecredit.co.id/customer/hc-oauth2/token", data);
    return response.data;
  } catch (error) {
    return { errorCode: error.response.status, message: error.response.data };
  }
};

export const fetchContract = async (authToken) => {
  try {
    const response = await axios.get("/v1/login/contract", {
      params: { authToken }
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

//Login open api upload KTP
export const authUploadKTP = async () => {
  const data = {
    credentials: {
      userName: `${process.env.usernameBasherLogin}`,
      password: `${process.env.passwordBasherLogin}`
    }
  };
  try {
    const response = await axios.post("https://api-preprod.homecredit.co.id/partners/login", data, {
      headers: {
        Authorization: `${process.env.authBasherLogin}`
      }
    });
    const result = await response.data;
    return result;
  } catch (error) {
    return error;
  }
};

// hit API for check status url
export const checkStatusUrl = async (data, token) => {
  const dataCheckStatus = { contractNumber: data };
  try {
    const response = await axios.post(
      "https://api-preprod.homecredit.co.id/basher-registration/short-link/validation/v1",
      dataCheckStatus,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    const result = await response.data;
    return result;
  } catch (error) {
    return error;
  }
};

//hit API for upload KTP
export const uploadKtp = async (data) => {
  const toFormData = (data) => {
    const dataContractNumber = data.contractNumber;
    const dataKtpDocument = data.ktpDocument;
    const dataKey = "file";

    const result = new FormData();
    result.append("contractNumber", dataContractNumber);
    result.append("ktpDocument", dataKtpDocument.file, dataKtpDocument.filename);

    return result;
  };

  const dataUpload = toFormData(data);

  const token = sessionStorage.getItem("basher-token");

  try {
    const response = await axios.post(
      "https://api-preprod.homecredit.co.id/basher-registration/document/v1",
      dataUpload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        }
      }
    );
    const result = await response.data;
    return result;
  } catch (error) {
    return error;
  }
};

// SA-Hiring check UME
export const checkStatusUme = async (code) => {
  const adjustCode = code.replace(/\s/g, "+");
  const dataCheckStatus = { encryptedMessage: adjustCode };
  try {
    const response = await axios.post(
      `${process.env.hcidExtrnalApiBaseUrl}/ume-management/deeplinkqr/deeplinks/deeplink`,
      dataCheckStatus,
      {
        auth: {
          username: `${process.env.umeId}`,
          password: `${process.env.umeSecret}`
        }
      }
    );
    return response.data.jsonData;
  } catch (error) {
    return { error };
  }
};

export const getMonetizationBanner = async () => {
  try {
    const response = await axios.get(
      `${process.env.apiURL}/api/v1/kentico/banner?codename=hciformulir_banner`,
      { codename: "hciformulir_banner" }
    );
    return response.data;
  } catch (error) {
    return;
  }
};
